import { SpSvg } from '../autogen/SpSvg'
import { CardTypes } from '../constants/cardTypes'
import { IMessageCardType } from './../types/IMessageCardTypes'

export const messageCardTypes: {
	[K in CardTypes]: IMessageCardType
} = {
	[CardTypes.INFO]: {
		mainColor: 'bg-sky-50',
		accentColor: 'bg-sky-600',
		iconColor: 'fill-sky-600',
		icon: SpSvg.BasicInfoCircle,
	},
	[CardTypes.SUCCESS]: {
		mainColor: 'bg-success-50',
		accentColor: 'bg-success-600',
		iconColor: 'fill-success-600',
		icon: SpSvg.BasicCheckCircle,
	},
	[CardTypes.WARNING]: {
		mainColor: 'bg-warning-50',
		accentColor: 'bg-warning-600',
		iconColor: 'fill-warning-600',
		icon: SpSvg.BasicAlertCircle,
	},
	[CardTypes.ERROR]: {
		mainColor: 'bg-error-50',
		accentColor: 'bg-error-600',
		iconColor: 'fill-error-600',
		icon: SpSvg.BasicErrorCircle,
	},
}
